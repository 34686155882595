import React from "react";
import ReformsMap from "../components/maps/ReformsMap";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../styles/styles.css";

const ReformsPage = (context) => {
  return (
    <Layout>
      <SEO
        imagePath={`og-images/reforms-map.png`}
        imageHeight={630}
        imageWidth={774}
        title="Routes to Reform Map" />
      <div className="page">
        <ReformsMap />
      </div>
    </Layout>
  );
};

export default ReformsPage;