import React, { useState } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import USAMap from "../USAMap";

import "../../styles/styles.css";
import "../../styles/redistricting-timeline.css";

const ReformsMap = () => {
    const contextData = useStaticQuery(graphql`
        query reformsMapQuery {
            allAirtable {
                nodes {
                  data {
                    Status
                    State_Abbreviation
                  }
                }
              }
        }
    `);

    const [SelectedStateFromMap, setSelectedStateFromMap] = useState(null);

    return (
        <USAMap
            SelectedStateFromMap={SelectedStateFromMap}
            setSelectedStateFromMap={setSelectedStateFromMap}
            stateData={contextData.allAirtable.nodes}
            dataFilter={(stateDatum, state) => stateDatum.data.State_Abbreviation === state}
            legend={{
                "Independent commission": "#58c0ac",
                "Other advisory/commission": "#acc783",
                "Legal routes to reform": "#FFC09F",
                "Divided government": "#cbafe9",
                "Public input": "#c28d2a"
            }}
            key={"reforms"}
            mapKey={"reforms"}
            mapTitle={<Link to="/reforms">State-by-State Redistricting Reform: The Local Routes</Link>}
            mapSubtitle={<>
                In all 50 states, there is a route to fair districts. But it depends on local conditions.
                <br />
                <b>Click on your state or select your state from the dropdown</b> to learn more about how best to achieve fair districts.
            </>}
            mapLegendTitle={`Reform route`}
            statusFieldName={`Status`}
        />
    );
};

export default ReformsMap;